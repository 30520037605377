.left-component {
    width: 40%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .plan-component {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        width: 60%;
        padding: 15px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 10px;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 5px;
    }
}

.cancer-cover {
    border: 1px solid #E44E80;
    border-radius: 10px;
    padding: 15px;
    background-color: #FDE7EF;
    margin-bottom: 20px;
    color: #C0305F;

    .cover-header {
        display: flex;
        align-items: center;
        justify-content: space-between; // Align items horizontally

        img.cancer-cover-image {
            width: 50px;
            height: 50px;
            margin-right: 15px;
        }

        .cover-text {
            display: flex;
            justify-content: space-between; // Align title and status horizontally
            width: 100%; // Take up full width

            .text {
                display: flex;
                flex-direction: column;
                align-items: flex-start; // Align text to the start
                margin-right: 15px; // Space between title and status

                h3 {
                    font-size: 18px;
                    margin: 0;
                }

                p {
                    margin-top: 5px;
                    color: #777;
                    font-size: 14px;
                }
            }

            .status {
                padding: 5px 10px;
                background-color: #f8f8f8;
                border-radius: 5px;
                font-size: 12px;
                height: fit-content;
                color: #666;
                white-space: nowrap;
                text-align: center; // Center text in status box
            }
        }
    }

    p {
        margin-top: 10px;
        color: #777;
        font-size: 14px;
    }

    @media screen and (max-width: 768px) {
        .cover-text {
            flex-direction: column; // Stack title and status vertically
            align-items: flex-start; // Align items to the start
            width: 100%; // Ensure full width on mobile
            .text {
                margin-right: 0; // Remove margin on mobile
            }
            .status {
                align-self: flex-end; // Align status to the end on mobile
            }
        }
    }
}