.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%;
    background-color: #fff;
    z-index: 1000;

    @media screen and (max-width: 768px) {
        padding: 2% 3%;
    }

    .left-container {
        display: flex;
        align-items: center;

        .logo {
            height: 40px;
            margin-right: 10px;

            @media screen and (max-width: 768px) {
                height: 30px;
            }

            @media screen and (max-width: 480px) {
                height: 25px;
            }
        }
    }

    .right-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        .circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #FA72684D;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            margin-right: 10px;
            font-size: 16px;
            border: 2px solid #FA7268;

            @media screen and (max-width: 768px) {
                width: 35px;
                height: 35px;
                font-size: 14px;
            }

            @media screen and (max-width: 480px) {
                width: 30px;
                height: 30px;
                font-size: 12px;
            }
        }

        .full-name {
            font-size: 16px;
            color: #000;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }

            @media screen and (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
}