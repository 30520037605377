.dashboard-component {
    background-color: #FAF9F9;
    display: flex;
    justify-content: center;
    align-items: center;
    .dashboard-content {
        width: 100%;
        height: 100vh;
        // max-width: 1200px;
        padding: 3%;
        border-radius: 10px;
        background-color: #f5f5f5;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 24px;
            text-align: left;
            margin-left: 5px;
        }

        .tab-menu {
            display: flex;
            //justify-content: center;
            flex-wrap: nowrap;
            overflow-x: auto;
            margin-bottom: 2%;
            width: 50%;
            scrollbar-width: none;
            -ms-overflow-style: none;
            button {
                flex: 1 1 auto;
                padding: 10px 20px;
                border: 2px solid transparent;
                background-color: transparent;
                cursor: pointer;
                font-size: 14px;
                border-radius: 50px;
                margin: 5px;
                color: #666;
                transition: all 0.3s ease-in-out;
                white-space: nowrap;

                &.active {
                    background-color: #fff;
                    border-color: #E44E80;
                    color: #E44E80;
                }

                &:hover:not(.active) {
                    background-color: #f8f8f8;
                }
            }

            /* Hides the scrollbar in Webkit browsers */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .dashboard-content {
            padding: 7%;
        }

        .tab-menu {
            width: 75% !important;
        }

        .tab-menu button {
            font-size: 14px;
            padding: 8px 12px;
        }
    }

    @media screen and (max-width: 768px) {
        .dashboard-content {
            padding: 7%;
            width : 80%;
            h2 {
                font-size: 20px;
            }
        }

        .tab-menu {
            justify-content: flex-start;
            overflow-x: auto;
            width: 100% !important;

            button {
                font-size: 14px;
                padding: 8px 12px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .dashboard-content {
            padding: 8%;

            h2 {
                font-size: 18px;
            }

            .tab-menu {
                justify-content: flex-start;
                overflow-x: auto;
                width: 100% !important;
                white-space: nowrap;

                button {
                    font-size: 12px;
                    padding: 6px 8px;
                }
            }
        }
    }
}