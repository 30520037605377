.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toast {
  background-color: #E44E80;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards, fadeOut 0.3s ease-out;
  animation-delay: 0s, var(--delay-duration); /* Delay fadeOut by --delay-duration */
  position: relative;
}
.toast .close-icon {
  position: absolute;
  top: 5px;
  right: 5px; /* Top-right corner */
  color: #fff;
  background-color: transparent; /* Transparent background */
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
