.success-modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darkened background */
  }
  
  .success-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #e44e80;
    border-radius: 50%;
    margin: 0 auto 16px;
  }
  
  .check-icon {
    width: 24px;
    height: 24px;
  }
  
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #e44e80;
    margin-bottom: 16px;
  }
  
  p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #666;
    margin-bottom: 16px;
  }
  
  .action-button {
    background-color: #e44e80;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 8px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: darken(#e44e80, 10%);
  }
  