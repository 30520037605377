.margin-bottom--10{
    padding-bottom: 10px;
}
.news-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    color: #E44E80;
    padding-top:10px;
}