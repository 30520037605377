/* Spinner.scss */

/* Full-page spinner styles */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* High z-index to overlay everything */
  }
  
  .spinner {
    width: 60px;
    height: 60px;
    border: 8px solid #FDE7EF;
    border-top: 8px solid #E44E80;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  