.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.unsubscribed-modal-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    outline: none;
    text-align: center;

    .modal-title {
        font-weight: bold;
        margin-bottom: 16px;
        text-align: center;
    }

    .modal-subtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 24px;
    }

    .close-button {
        width: 100%;
        margin-bottom: 16px;
        border-color: #333;
        color: #333;
        text-transform: none;
    }

    .donation-section {
        background-color: #fafafa;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 16px;

        .donation-text {
            font-size: 14px;
            color: #666;
            margin-bottom: 12px;
        }

        .donate-button {
            width: 100%;
            background-color: #e91e63;
            color: #fff;
            text-transform: none;

            &:hover {
                background-color: #d81b60;
            }
        }
    }

    .terms-text {
        font-size: 12px;
        color: #666;

        a {
            color: #e91e63;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .unsubscribed-modal-box {
        max-width: 85%;
        padding: 15px;
        border-radius: 10px;
    }

    .modal-title {
        font-size: 18px;
        margin-bottom: 12px;
    }

    .modal-subtitle {
        font-size: 13px;
        margin-bottom: 16px;
    }

    .donation-section {
        padding: 15px;
    }

    .donate-button,
    .close-button {
        font-size: 14px;
    }

    .terms-text {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .unsubscribed-modal-box {
        max-width: 75%;
        padding: 12px;
    }

    .modal-title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .modal-subtitle {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .donation-section {
        padding: 12px;
    }

    .donate-button,
    .close-button {
        font-size: 12px;
    }

    .terms-text {
        font-size: 10px;
    }
}
