.forgot-pincode {
    cursor: pointer;
    font-size: 14px;
    margin-top: 0px !important;
    display: flex;
    justify-content: flex-end;
}

.forgot-pincode-text:hover {
    color: #e1638d;
    /* Darker blue on hover */
}
.forgot-pincode-text{
    width:fit-content;
    margin-top:0px !important;
}