.mobile-contribution-component {
    background-color: #FAF9F9;
    padding: 10%;
    max-width: 450px;
    margin: 0 auto;
    font-family: Arial, sans-serif;

    .contribution-header {
        text-align: left;
        margin-bottom: 20px;

        .back-button {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .info-text {
            margin-top: 7%;
            background-color: #fff;
            border: 1px solid #ECECEC;
            border-radius: 8px;
            padding: 12px;
            font-size: 14px;
            color: #E44E80;
            line-height: 1.5;
            margin-bottom: 20px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

            strong {
                color: #E44E80;
            }
        }
    }

    .plan-selector {
        margin-top: 7%;
        margin-bottom: 10%;
    }
}
.mobile-stats-component {
    width: 100%;

    .header-text {
        font-size: 18px !important;
    }

    .inner-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 18px;
            color: #333;
        }
    }

    .continue-button {
        width: 100%;
        padding: 15px;
        background-color: #E44E80;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-top: 5%;

        &:hover {
            background-color: #d13b6f;
        }
    }
}
