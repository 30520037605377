.table-wrapper {
    padding: 10px;
    /* Optional: adds padding to the table wrapper */
}

.table-container {
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.overflow-scroll {
    overflow: auto;
}
.align-center{
    align-content: center;
    align-items: center;
}
.user-table-header {
    background-color: #c7c5c5;
    font-weight: bold;
}

.table-row {
    &:hover {
        background-color: #f0f8ff;
    }
}

.table-cell {
    td{
    padding: 8px;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    /* Prevents text from breaking */
    text-align: left;
    /* Keeps the text aligned to the left */
    }
}

.width--30 {
    width: 30%;
}

.MuiInputBase-root {
    margin-top: 0px !important;
}
/* Users.scss */

.policy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .header-title {
    font-size: 24px;
    font-weight: 600;
  }
}

.table-container {
  overflow-x: auto;
}

.user-table-header {
  background-color: #f0f0f0; /* Light background for header */
  font-weight: 600;
  text-align: left;
}

.table-cell {
  padding: 12px;
  text-align: left;
  white-space: nowrap; /* Prevents text from wrapping */
}

.table-row {
  &:nth-of-type(even) {
    background-color: #f9f9f9; /* Stripe effect for table rows */
  }
}

.status-pill {
  font-weight: bold;
  border-radius: 20px;
}

.text-center {
  text-align: center;
}

.overflow-scroll {
  overflow-x: auto;
}

.policy-header .MuiTextField-root {
  width: 100%;
}

.policy-header .MuiInputBase-root {
  background-color: #fff;
  border-radius: 4px;
  height: 40px;
}

/* Adjustments for table responsiveness */
@media (max-width: 768px) {
  .table-container {
    display: block;
  }
  
  .table-row,
  .table-cell {
    font-size: 14px; /* Slightly reduce font for smaller screens */
  }
}
