.change-number-form {
    width: 100% !important;
}

.breadcrumb {
    margin-left: 30px !important;
    margin-bottom: 16px !important;
    color: #666 !important;
}

.breadcrumb-separator {
    font-size: 1rem !important;
    margin-bottom: 2px !important;
}

.change-number-title {
    margin-left: 30px !important;
    margin-top: 5px !important;
    margin-bottom: 16px !important;
    font-weight: bold !important;
}

.gray-background-container {
    background-color: #FAF9F9 !important;
    min-height: 100vh !important;
    padding: 20px !important;
    display: flex !important;
    justify-content: start !important;
    align-items: flex-start !important;
    width: 100% !important;
}

.gray-background-content {
    padding: 16px !important;
    border-radius: 8px !important;
    width: 100% !important;
    max-width: 500px !important;
    @media (max-width: 600px) {
        width: 85% !important;  }
}

.change-number-description {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: 200 !important;
    line-height: 16.94px !important;
    letter-spacing: 0.005em !important;
    text-align: left !important;
    color: #808080 !important;
}

.next-btn {
    background-color: #E44E80 !important;
    color: #fff !important;
    padding: 12px 30px !important;
    font-size: 1rem !important;
    border-radius: 10px !important;
    // width: 40% !important;
    box-shadow: none !important;

    &:hover {
        background-color: darken(#E44E80, 10%) !important;
    }
}


.MuiTextField-root {
    width: 100%;

    @media (max-width: 600px) {
        width: 90%; /* Make input fields smaller for mobile view */
    }
}