.cancer-cover-container {
    display: flex;
    justify-content: space-evenly;
    background-color: #f5f5f5;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;

        .right-component {
            display: none;
        }

        .left-component {
            width: 100%;
        }
    }
}

.right-component {
    width: 40%;
    margin-top: -7%;

    .header-text {
        font-size: 18px !important;
    }

    .inner-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 18px;
            color: #333;
        }
    }
}

.plan-stats {
    padding: 1%;
    margin: 0 auto;

    .stats-title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        margin-bottom: 16px;
    }

    .stats-content {
        display: flex;
        flex-direction: column;

        .stat-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            font-size: 14px;
            color: #666;

            &:last-child {
                margin-bottom: 0;
            }

            span:last-child {
                font-weight: bold;
            }
        }
    }

    .divider {
        margin: 20px 0;
        /* Space around the divider */
        border-bottom: 1px solid #ececec;
        /* Horizontal line */
    }

    .terms {
        text-align: center;
        font-size: 14px;
        color: #666;
        margin-top: 20px;

        a {
            color: #e91e63;
            text-decoration: none;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}