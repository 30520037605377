.announcement-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.announcement-card{
    min-width:100%;
    @media screen and (max-width: 768px) {
        width:100% !important;
        max-width:100%;
    }
    transition: transform 0.6s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 16px 30px rgba(0, 0, 0, 0.15);
    }
}