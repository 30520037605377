.otp-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.title-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 30px 30px 30px;
  width: 350px;
  text-align: start;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 25.92px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #E44E80;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;

    .change-number {
      color: #E44E80;
      cursor: pointer;
    }
  }

  .continue-button {
    background-color: #E44E80;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      background-color: #d03b70;
    }
  }

  .resend-text {
    font-size: 12px;
    color: #999;
    margin-top: 15px;

    span {
      color: #E44E80;
      font-weight: 600;
    }
  }
}

@media (max-width: 576px) {
  .otp-modal-wrapper {
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    min-height: 100vh;
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
  }

  .otp-modal {
    width: 100%;
    padding: 10px 15px 20px 15px;
    max-width: 100%;
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove box shadow */
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 12px;
  }

  .continue-button {
    font-size: 14px;
  }
}

.close-button{
  position: absolute;
  top: 10px;
  right: 0px;
  cursor: pointer;
}