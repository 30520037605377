@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  
}
.d-flex{
  display: flex;
}
.justify-space-between{
  justify-content: space-between;
  align-items: center;
  align-self: center;
}
.disabled-button{
  background-color: #E44E80;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: not-allowed !important;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.5;
}
.text-center{
  text-align: center !important;
}
.show-scrollbar::-webkit-scrollbar {
  display: block !important;
  width: 2px;
  height:2px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar {
  display: black;
  width: 6px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
.text-center{
  text-align: center !important;
  td{
    text-align: center !important;
  }
  th{
    text-align: center !important;
  }
}
