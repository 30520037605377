/* src/components/NotFound.css */
.container {
    text-align: center;
    padding: 50px;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color:#E44E80;
  }
  
  .message {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .homeLink {
    text-decoration: none;
    color: #E44E80;
    font-size: 1.2rem;
  }

  