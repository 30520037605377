.payment-method-container {
    background-color: #faf9f9;
    padding: 5%;
    border-radius: 12px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .description,
    .sub-description {
        font-size: 16px;
        color: #666;
        margin-bottom: 20px;
        line-height: 1.5;
    }

    .payment-options {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 30px;

        label {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 15px 20px;
            border-radius: 8px;
            border: 2px solid transparent;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            input {
                margin-right: 15px;
                display: none;
            }

            img {
                width: 40px;
                margin-right: 15px;
            }

            &.selected {
                border-color: #e44e80;
            }
        }
    }

    .continue-button {
        width: 100%;
        background-color: #e44e80;
        color: #fff;
        padding: 15px;
        height:48px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #c73b65;
        }
    }

    @media screen and (max-width: 1024px) {
        max-width: 500px;
        padding: 10%;

        h2 {
            font-size: 22px;
        }

        .description,
        .sub-description {
            font-size: 15px;
        }

        .payment-options label {
            padding: 12px 18px;

            img {
                width: 35px;
            }
        }

        .continue-button {
            font-size: 15px;
            padding: 12px;
        }
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 0 10%;
        margin-top: 15%;

        h2 {
            font-size: 20px;
        }

        .description,
        .sub-description {
            font-size: 14px;
        }

        .payment-options label {
            padding: 10px 15px;

            img {
                width: 30px;
            }
        }

        .continue-button {
            font-size: 14px;
            padding: 10px;
        }
    }

    @media screen and (max-width: 480px) {

        h2 {
            font-size: 18px;
        }

        .description,
        .sub-description {
            font-size: 12px;
        }

        .payment-options label {
            padding: 8px 12px;

            img {
                width: 25px;
            }
        }

        .continue-button {
            padding: 8px;
        }
    }
}

.payment-name {
    display: grid !important;
}

.payment-number-container {
    display: flex;
    align-items: center;

    .payment-number {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size:13px;
        margin-top: 5px;
        color:#666;

        .edit-icon {
            cursor: pointer;
            color: #999;
            transition: color 0.2s ease;

            &:hover {
                color: #333;
            }
        }

       
    }
}
.payment-number-input {
    display: inline !important;
    width: 120px !important;
    padding: 5px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}