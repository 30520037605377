.button-container{
    display: flex;
    justify-content: flex-end;
    padding-top:10px;
}
.save-button{
    width:100px;
}
.upload-button{
    background-color: #E44E80;
}