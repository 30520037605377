.image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ensure it covers the entire viewport height */
    overflow: hidden;
    padding: 0;
    background: linear-gradient(#85143A99, #85143A99), /* Color overlay */
    url('../../../public/images/signupBg.png'); 
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent image tiling */
  }

  .content{
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 2;
    color: white;
    text-align: center;
    top: 20%;
    padding: 0 20px;
    justify-content: space-between
  }

  .logo-and-text {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    color: white;
    text-align: center;
    top: 30%;
  
    padding: 0 20px;
  }

  .text-box { 
    width: 463px;
    height: 212px;
    opacity: 1; /* Set to 1 to make it visible */
    font-family: 'Inter', sans-serif;
    font-size: 44.32px;
    font-weight: 500;
    line-height: 53.18px;
    text-align: left;
    color: #FFFFFF; /* Text color set to white */
    margin-top: 5%;
    margin-left: 27%;
  }

  .sub-text-box{
  font-size: 20px;
  font-weight: 400;
  line-height: 23.1px;
  text-align: left;
  margin-left: 27%;
  margin-top: 5%;
  white-space: nowrap
  }

@media (max-width: 576px) {
  .image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ensure it covers the entire viewport height */
    overflow: hidden;
    padding: 0;
    background: none; /* Color overlay */
  }

  .content {
    flex-direction: column;
    top: 10%;
    padding: 0;
    justify-content: center;
    align-items: center;
    top: 0%;
  }

  .logo-and-text {
    position: relative;
    display: flex;
    height: 20em;
    flex-direction: column;
    z-index: 2;
    color: white;
    text-align: start;
    padding: 0;
    background: linear-gradient(#85143A99, #85143A99), /* Color overlay */
    url('../../../public/images/signupBg.png');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent image tiling */
  }

  
    .text-box {
      width: 90%;
      height: auto;
      font-size: 16px;
      line-height: 22px;
      margin-top: 10px;
      margin-left: 10%;
      margin-right: 5%;
      text-align: start;
      word-wrap: break-word;
    }
  
    .sub-text-box{
      font-size: 15px;
      font-weight: 400;
      line-height: 23.1px;
      text-align: start;
      margin-left: 10%;
      margin-top: 5%;
      white-space: normal;
      width: 80%;
      }
  
}