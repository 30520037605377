.plans {
    margin-bottom: 20px;

    .plan {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: border-color 0.3s;
        position: relative;
        background-color: #fff;

        .recommended {
            display: block;
                color: #fff;
                font-size: 12px;
                background-color: #E44E80;
                padding: 2px 8px;
                border-radius: 5px;
                position: absolute;
                right: 10px;
                top: 80%;
                font-weight: bold;
        }

        &:hover {
            border-color: #E44E80;
        }

        &.selected {
            border: 2px solid #E44E80;

            .recommended {
                display: block;
                color: #fff;
                font-size: 12px;
                background-color: #E44E80;
                padding: 2px 8px;
                border-radius: 5px;
                position: absolute;
                right: 10px;
                top: 80%;
                font-weight: bold;
            }
        }

        .plan-image {
            width: 30px;
            height: 30px;
            margin-right: 15px;
        }

        .plan-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            padding-right: 10px;

            span {
                font-size: 14px;

                &:first-child {
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                &:last-child {
                    font-size: 12px;
                    color: #777;
                }
            }
        }

        input[type="radio"] {
            appearance: none;
            border: 2px solid #E44E80;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            position: relative;
            outline: none;
            cursor: pointer;
            margin-left: auto;

            &:checked {
                background-color: #E44E80;
                border-color: #E44E80;
                box-shadow: 0 0 0 4px rgba(228, 78, 128, 0.1);
            }

            &:checked::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .continue-button {
        width: 100%;
        padding: 15px;
        background-color: #E44E80;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        margin-top: 7%;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #d13b6f;
        }
    }
}
