.header-with-logo {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 2; 
    text-align: center;
    margin-top: 5%;

    .logo-image {
        width: 40px; /* Fixed size for consistency */
        height: auto; /* Maintain aspect ratio */
        opacity: 1;  
        z-index: 2;
        padding-top: 2%;
        background-color: transparent
    }

    .header-image {
        width: 100px; /* Larger than logo image for visual hierarchy */
        height: auto; /* Maintain aspect ratio */
        opacity: 1;  
        z-index: 2;
        margin-left: 2%;
        padding-top: 2%;
    }
}


@media (max-width: 576px) {
    .header-with-logo {
    .logo-image {
        opacity: 1;  
        z-index: 2;
        padding-top: 2%;
        background-color: transparent;
        margin-left:10px;
    }
    
    .header-image {
        width: 100px; /* Larger than logo image for visual hierarchy */
        height: auto; /* Maintain aspect ratio */
        opacity: 1;  
        z-index: 2;
        margin-left: 2%;
        padding-top: 2%;
    }
}
}