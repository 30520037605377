.dropdown-container {
    position: fixed;
    top: 73px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 299px;
    height: auto;
    z-index: 1000;
}

.dropdown-menu {
    
    
    .dropdown-section {
        display: flex;
        align-items: center;
        padding: 10px 10px 0px 10px;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #FA72684D;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            margin-right: 10px;
            font-size: 16px;
            border: 2px solid #FA7268;
        }

        .user-info {
            display: flex;
            flex-direction: column;

            .user-name {
                font-family: 'Inter', sans-serif;
                font-size: 15px;
                font-weight: 400;
                line-height: 23.1px;
                text-align: left;
                color: #000;
            }
        }

        .user-email {
            font-family: 'Inter', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 23.1px;
            text-align: left;
        }
    }

    .dropdown-item {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 23.1px;
        text-align: left;
        padding: 10px;
        width: 100%;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #333;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #f9f9f9;
        }
    }

    /* New logout container styling */
    .logout-container {
        background-color: #F7F7F7;
        padding: 10px;
        border-radius: 10px;
        margin-top: 10px;
    }

    .logout {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 23.1px;
        text-align: center;
        width: 100%;
        color: #E44E80;
        font-weight: bold;
        border: 1px solid #E44E80;
        border-radius: 6px;
        background-color: transparent;
        padding: 10px;
        transition: background-color 0.2s ease, color 0.2s ease;

        &:hover {
            background-color: #F4CBD9;
            color: #fff;
        }
    }
}
