.plan-card {
    background-color: #fff;
    border: 1px solid #ececec;
    padding: 20px;
    border-radius: 12px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    font-family: 'Arial', sans-serif;

    .plan-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .plan-info {
            display: flex;
            align-items: center;

            .plan-avatar {
                width: 40px;
                height: 40px;
                background-color: #d3d3d3;
                border-radius: 50%;
                margin-right: 12px;
            }

            .plan-details {
                .plan-name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                }
            }
        }

        .plan-price-info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            /* Align everything to the right */

            .plan-price {
                font-size: 14px;
                color: #666;
                margin-bottom: 10px;
                /* Add space between price and button */
            }

            .change-plan {
                background: none;
                border: none;
                color: #e91e63;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                align-items: center;

                .change-icon {
                    margin-right: 6px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .plan-notice {
        background-color: #f7f7f7;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        border: 1px solid #ececec;

        .icon-info {
            color: #e91e63;
            font-size: 20px;
            margin-right: 12px;
        }

        p {
            font-size: 14px;
            color: #666;
            margin: 0;
            line-height: 1.5;
        }
    }

    .unsubscribe-button {
        width: 100%;
        padding: 14px 16px;
        background-color: #fff;
        border: 1px solid #e91e63;
        border-radius: 8px;
        color: #e91e63;
        cursor: pointer;
        font-size: 16px;
        text-align: center;
        transition: background-color 0.3s;

        &:hover {
            background-color: #ffe5ed;
        }
    }
}