.table-header {
    background-color: #f4f4f4;
  }
  
  .mobile-card {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .mobile-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  
    .MuiIconButton-root {
      margin-left: 8px;
    }
  }
  
  .height--40{
    height: 40vh;
    overflow: scroll;
  }