.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 5%;
    text-align: center;
    background-color: #faf9f9;
    border-radius: 12px;

    .coming-soon-image {
        width: 40%;
        transition: width 0.3s ease;
    }

    .coming-soon-text {
        font-size: 18px;
        color: #666;
        line-height: 1.5;
        transition: font-size 0.3s ease;
    }

    @media screen and (max-width: 768px) {
        .coming-soon-image {
            width: 50%;
        }
        .coming-soon-text {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 10%;
        .coming-soon-image {
            width: 60%;
        }
        .coming-soon-text {
            font-size: 14px;
        }
    }
}
