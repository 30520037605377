.policy-header {
    text-align: center;
    margin-bottom: 20px;
}

.header-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.report-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}
.filter-button {
    background-color: #E44E80;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      background-color: #d03b70;
    }
  }
.filter-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}
.report-wrapper {
    width: 100%;
}

.report-list {
    padding: 0;
}

.report-list .MuiListItem-root {
    margin-bottom: 8px;
}

.report-list .MuiListItemText-root {
    font-size: 1rem;
}

.report-list .MuiListItemIcon-root {
    margin-right: 8px;
}

.report-list .MuiIconButton-root:hover .MuiSvgIcon-root {
    color: gold;
}
.report-list {
    padding: 0;
}

.report-list .MuiListItem-root {
    margin-bottom: 12px;
    padding: 15px;
    background-color: white; /* Set a white background for the list items */
    border: 1px solid #e0e0e0; /* Add a subtle border */
    border-radius: 8px; /* Round the corners */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.report-list .MuiListItem-root:hover {
    background-color: #f0f0f0; /* Change background color on hover */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.report-list .MuiListItemText-root {
    font-size: 1rem;
    font-weight: 500; /* Slightly increase font weight for prominence */
    color: #333; /* Darker color for better readability */
}

.report-list .MuiListItemText-root:hover {
    color: #E44E80; /* Change text color on hover */
}
