// AnnouncementsForm.scss
.announcement-form {
    margin-top: 20px;
}

.upload-button {
    margin: 16px 0;
}

@media screen and (max-width: 768px) {
    .MuiTextField-root {
        width: 100% !important;
    }
}

.table-header {
    background-color: #f4f4f4;
}

.mobile-card {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.mobile-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .MuiIconButton-root {
        margin-left: 8px;
    }
}

.overflow-scroll {
    overflow-x: auto;
}

.height--40 {
    height: 40vh;
    overflow: scroll;
}