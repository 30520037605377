.profile-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 1rem 2rem 2rem 2rem;
    background-color: #FAF9F9;
    min-height: 100vh;
}
  
.profile-title {
    font-size: 24px;
    margin-bottom: 0rem !important;
    @media (max-width: 600px) {
       margin-top: 0rem !important; 
       margin-left: 0.5rem !important;
       font-size: 24.92px !important;
       font-weight: 550 !important;
       line-height: 31.1px !important;
      }
}
  
.profile-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
  
.profile-paper {
    flex: 2;
    padding-left: 2rem;
    padding-right: 6rem;
    border-radius: 8px;
    background-color: transparent !important;

    @media (max-width: 600px) {
        padding-right: 2rem !important; 
      
      }
    
}

.MuiTypography-root.field-label {
    font-size: 0.875rem;
    color: #333;
    font-weight: bold;
    margin-top: 1rem;
}

.mobile-number-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MuiTypography-root.mobile-number {
    font-size: 1rem;
    margin-top: 5px;
}

.done-btn-container {
    display: flex;
    justify-content: start;
    margin-top: 2.5rem;
}

.MuiButton-root.change-pin-btn {
    text-transform: none;
    font-weight: normal;
    padding: 10px 20px;
    font-size: 0.875rem;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    background-color: white;
    color: inherit;
    box-shadow: none;
    width: fit-content;
    margin-top: 10px;
    height: 50px;
}

.MuiInputBase-root {
    background-color: white;
    border-radius: 10px !important;
    margin-top: 10px;
    height: 50px;
}

.text-field .MuiInputBase-input {
    color: #333;
    font-size: 1rem;
}

