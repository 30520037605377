.recurring-payment-modal {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.recurring-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-title {
    font-size: 24px;
    color: #333;
}

.recurring-close-button {
    color: #ff0000;
}

.plan-container {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.plan-name {
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
}

.amount-field {
    background-color: #f2f2f2; // Makes it look disabled
}

.recurring-continue-button {
    background-color: #E44E80;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    width: 20%;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        background-color: #d03b70;
    }
}

.button-container {
    display: flex;
    justify-content: flex-end;
}