.left-nav-container {
  display: flex;

  .left-nav-drawer {
    width: 240px;
    margin-top: 70px;
    background-color: #f8f8f8;
    border-right: 1px solid #e0e0e0;
    height: 100%;
    box-sizing: border-box;
  }

  .nav-item {
    cursor: pointer;

    &.active {
      background-color: #fff;
      border-right: 4px solid #d13b6f;
      cursor: pointer;
    }
  }
}

.main-content {
  margin-left: 240px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}