.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.unsubscribe-modal-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    outline: none;

    .modal-title {
        font-weight: bold;
        margin-bottom: 16px;
        text-align: left;
    }

    .modal-subtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 16px;
    }

    .continue-button {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;
        border-color: #333;
        color: #333;
        text-transform: none;
    }

    .confirm-button {
        width: 100%;
        background-color: #e91e63;
        color: #fff;
        text-transform: none;

        &:hover {
            background-color: #d81b60;
        }
    }
}

@media screen and (max-width: 768px) {
    .unsubscribe-modal-box {
        max-width: 85%;
        padding: 15px;
        border-radius: 10px;
    }

    .modal-title {
        font-size: 18px;
        margin-bottom: 12px;
    }

    .modal-subtitle {
        font-size: 13px;
        margin-bottom: 12px;
    }

    .continue-button,
    .confirm-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    .unsubscribe-modal-box {
        max-width: 75%;
        padding: 12px;
    }

    .modal-title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .modal-subtitle {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .continue-button,
    .confirm-button {
        font-size: 12px;
    }
}
