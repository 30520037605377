.cover-details {
    list-style-type: none;
    padding: 0;

    li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.4;
        color: #555;

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 15px;
            flex-shrink: 0;
        }

        div {
            flex: 1;

            strong {
                display: block;
                font-weight: 600;
                color: #000;
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                color: #777;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}