.policy-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Responsive grid layout
    gap: 16px; // Space between cards
    padding: 20px;
}

.card-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 6px 8px rgba(4, 4, 4, 0.1);
    padding: 16px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.card-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

}
.icon-wrapper {
    cursor: pointer;
    color:rgb(165, 164, 164);
    :hover{
        color: #E44E80;
    }
}
.card-container:hover {
    transform: scale(1.02); // Slight hover effect
}

// Media Queries for responsiveness
@media (max-width: 600px) {
    .policy-header h2 {
        font-size: 24px;
        text-align: center;
    }

    .policy-button {
        font-size: 16px;
        padding: 10px;
        background-color: #E44E80;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-top: 5%;

        &:hover {
            background-color: #d13b6f;
        }
    }

    .policy-list {
        grid-template-columns: 1fr; // 1 column for small screens
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .policy-list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Adjust grid for medium screens
    }
}

@media (min-width: 1201px) {
    .policy-list {
        grid-template-columns: repeat(3, 1fr); // 3 columns for large screens
    }
}

.policy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.policy-button {
    font-size: 16px;
    padding: 10px;
    background-color: #E44E80;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #d13b6f;
    }
}


//
@media screen and (max-width: 480px) {
    .tab-menu {
        justify-content: flex-start;
        overflow-x: auto;
        width: 100% !important;
        white-space: nowrap;

        button {
            font-size: 12px;
            
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard-content {
        padding: 7%;

        h2 {
            font-size: 20px;
        }
    }

    .tab-menu {
        justify-content: flex-start;
        overflow-x: auto;
        width: 100% !important;

        button {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .dashboard-content {
        padding: 7%;
    }


    .tab-menu button {
        font-size: 14px;
    }
}

.tab-menu {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 2%;
    &::-webkit-scrollbar {
        display: none;
      
    }
    button {
        flex: 1 1 auto;
        border: 2px solid transparent;
        padding: 10px 20px;
        background-color: white;
        cursor: pointer;
        font-size: 14px;
        border-radius: 50px;
        margin: 5px;
        color: #666;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
        max-width: 250px;

        &.active {
            background-color: #fff;
            border-color: #E44E80;
            color: #E44E80;
        }

        &:hover:not(.active) {
            background-color: white;
        }
    }
}

.button-group {
    display: flex;
    gap: 10px;
}

.tab-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tab-icons {
    display: flex;
    gap: 2px; // Small space between icons

    .MuiIconButton-root {
        padding: 0px; // Make the icon buttons smaller
    }
}

/* Show scrollbar when class is added */
