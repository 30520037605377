.recurring-payment-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Responsive grid layout
    gap: 16px; // Space between cards
    padding: 20px;
}

.recurring-card-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 6px 8px rgba(4, 4, 4, 0.1);
    padding: 16px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.justify-end{
    justify-content: flex-end;
}
.recurring-card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.recurring-card-container:hover {
    transform: scale(1.02); // Slight hover effect
}

// Media Queries for responsiveness
@media (max-width: 600px) {
    .recurring-payment-list {
        grid-template-columns: 1fr; // 1 column for small screens
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .recurring-payment-list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Adjust grid for medium screens
    }
}

@media (min-width: 1201px) {
    .recurring-payment-list {
        grid-template-columns: repeat(3, 1fr); // 3 columns for large screens
    }
}
.recurring-mobile-card {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.recurring-mobile-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .MuiIconButton-root {
        margin-left: 8px;
    }
}





