.card {
    position: relative;
    margin-right: 1%;
    width: 30%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding-left: 3%;
    padding-right: 3%;
    box-sizing: border-box;

    h2 {
        font-size: 25.92px;
        margin-bottom: 10px;
        font-family: Inter;
        font-weight: 550;
        line-height: 31.1px;
        text-align: left;
        color: #E44E80;
    }
}

.form-content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .form-label {
        color: #333;
        font-family: 'Inter', sans-serif;
        font-size: 12.5px;
        font-weight: 550;
        line-height: 15px;
        text-align: left;
    }

    .input-wrapper {
        display: flex;
        // align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        background-color: #f9f9f9;
        color:black;
        .input-icon {
            margin-right: 10px;
            font-size: 18px;
            display: flex;
            align-items: center;
            padding-right: 10px;
            border-right: 1px solid #ccc;
            /* Add vertical line */
        }

        .input-field {
            width: 100%;
            border: none;
            outline: none;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            color: #333;
            background-color: transparent;
            // padding-left: 10px;
            /* Add padding to create space after the line */
            height: 100%;

            &::placeholder {
                color: #aaa;
            }
        }
    }
}

.align-at-ends {
    height: 2vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
}

.optional {
    font-size: 14px;
    color: #999;
}

.info-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #999;
    text-align: left;
    margin-top: 5%;
}

.submit-button {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    background-color: #E44E80;
    border: none;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    margin-top: 20px;
    height: 48px;
    width: 100%;
    display: flex;
    /* Make the button a flex container */
    justify-content: center;
    /* Horizontally center the text */
    align-items: center;
    /* Vertically center the text */

    &:hover {
        background-color: #d03b70;
    }
}

.error-text {
    color: #d32f2f;
    font-size: 0.875rem;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left
  }
  

@media (max-width: 576px) {
    .card {
        width: 90%;
        height: auto;
        margin: 0px auto 20px auto;
        padding: 20px;
        border-radius: 0;
        box-sizing: none
    }

    .form-label {
        font-size: 14px;
    }

    .info-text {
        font-size: 12px;
        margin-top: 10px;
    }

    .submit-button {
        margin-top: 20px;
    }
}

.error-text {
    color: red; // Or any color that fits your design
    font-size: 0.875rem; // Adjust as needed
    margin-top: 0.5rem; // Space between the error message and the input field
}