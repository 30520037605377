// HospitalsPage.scss

a {
  color: #E44E80;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.hospitals-page {
  background: linear-gradient(to right, #e0f7fa, #f1f8e9);
  padding: 40px;

  .MuiCard-root {
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 16px 30px rgba(0, 0, 0, 0.15);
    }
  }

  .MuiTypography-h5 {
    color: #E44E80;
    ;
    font-weight: 600;
  }

  .MuiAvatar-root {
    border: 3px solid #E44E80;
    ;
  }

  button {
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #E44E80;
      ;
      color: white;
    }
  }
}

.hospital-card {

  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}