/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  
  /* Popup content box */
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
  }
  
  /* Close button (the cross) */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 34px;
    cursor: pointer;
  }
  
  /* Action button at the bottom */
  .action-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #E44E80;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #d03b70;
  }
  