.otp-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 3px;
  
    .otp-input {
      width: 60%;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
      text-align: center;
      font-size: 20px;
      color: #333;

      &::placeholder {
        color: #aaa;
    }
    }
  }
  