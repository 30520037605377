.profile-incomplete {
    border: 1px solid #E44E80;
    border-radius: 10px;
    padding: 15px;
    background-color: #FCF6D2;
    margin-bottom: 20px;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between; // Align items horizontally
        color: #C0305F;


    }

    &-cover-text {
        display: flex;
        justify-content: space-between; // Align title and status horizontally
        width: 100%; // Take up full width
    }

    &-text {
        font-size: 14px;
        margin: 10px 0px 10px 0px;
    }

    &-cover-header {
        display: flex;
        align-items: center; // Align text to the start
        margin-right: 15px; // Space between title and status
        font-size: 18px;
        font-weight: bold;
    }

    &-button {
        background-color: #E44E80;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        transition: background-color 0.3s;

        &:hover {
            background-color: #C0305F;
        }
    }
}